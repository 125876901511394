.ana_tablo { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;}
.ana_tablo_arkaplan { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; }

.icerik_tablosu {max-width: 500px;}
.icerik_tablosu_bosluklari {padding: 30px 0 15px 0;}
.logo-gorseli { display: block; font-family: Helvetica, Arial, sans-serif; color: #ffffff; font-size: 16px;}

.sayfa_basligi_tablosu_disi {-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding: 15px;}
.sayfa_basligi_tablosu { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; max-width: 500px; border-collapse: collapse; }
.sayfa_basligi_tablo_ici { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt;}
.sayfa_basligi_yazilar_tablosu {-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;}
.sayfa_basligi_tablosu_ana_baslik {-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-size: 32px; font-family: Helvetica, Arial, sans-serif; color: #333333;}
.sayfa_basligi_tablosu_alt_baslik {-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding: 20px 0 0 0; font-size: 16px; line-height: 25px; font-family: Helvetica, Arial, sans-serif; color: #666666;}

.butonlar_alani {-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding: 15px 0 50px 0;}
.butonlar_alani_tablolari {-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse;}
.butonlar_alani_tablolari_ici { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; }
.butonlar_alani_notlar { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-size: 14px; line-height: 18px; font-family: Helvetica, Arial, sans-serif; color: #666666; }
.butonlar_alani_buton_sutunlari { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding-top: 25px; }
.butonlar_alani_buton_kutulari { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 3px; cursor: pointer; }
.butonlar_alani_onay_butonu { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; width: 110px; font-size: 16px; font-family: Helvetica, Arial, sans-serif; color: #ffffff; text-decoration: none; color: #ffffff; text-decoration: none; border-radius: 3px; padding: 15px 25px; border: 1px solid #0CCE6B; display: inline-block; }
.butonlar_alani_red_butonu { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; width: 110px; font-size: 16px; font-family: Helvetica, Arial, sans-serif; color: #ffffff; text-decoration: none; color: #ffffff !important; text-decoration: none; border-radius: 3px; padding: 15px 25px; border: 1px solid #DB4437; display: inline-block; }

.bilgi_alani_yazilar { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-family: Arial, sans-serif; color: #333333; font-size: 16px; }
.bilgi_alani_yazi_tablosu_dis_tablosu { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 47%; border-collapse: collapse; }
.bilgi_alani_yazi_tablosu_disi { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding: 0 0 10px 0; }
.bilgi_alani_yazi_tablosu { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; }

.bilgi_alani_sonuc_sifirlama { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; } 
.bilgi_alani_sonuc_sifirlama_2 { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; }

.bilgi_alani_sonuc_yazi_1 { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-family: Arial, sans-serif; color: #333333; font-size: 16px; font-weight: bold; }
.bilgi_alani_sonuc_yazi_2 { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-family: Arial, sans-serif; font-size: 16px; }
.bilgi_alani_sonuc_yazi_3 {-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-family: Arial, sans-serif; color: #0CCE6B; font-size: 16px; font-weight: bold;}
.bilgi_alani_sonuc_yazi_4 { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-family: Arial, sans-serif; color: #333333; font-size: 16px; font-weight: bold; }

.bilgi_alani_tablolar { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; max-width: 500px; border-collapse: collapse; }
.bilgi_alani_tablolar_disi { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding: 10px 0 0px 0; border-top: 1px solid #eaeaea; border-bottom: 1px dashed #aaaaaa; }

.bilgi_alani_yazi_baslik { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-family: Arial, sans-serif; color: #333333; font-size: 16px; }
.bilgi_alani_yazi_baslik_disi { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding: 0; }
.bilgi_alani_yazi_tablosu_disi { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding: 0 0 10px 0; }
.bilgi_alani_sifirlama { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; }
.bilgi_alani_yuzde_47 { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 47%; border-collapse: collapse; }
.bilgi_alani_ust_sinir { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding: 10px 0 0 0; border-top: 1px dashed #aaaaaa; }
.bilgi_alani_ust_sinir_ici { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; }

.bilgi_alani_bolum_2 { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding: 10px 0 0 0; border-top: 1px solid #eaeaea; }
.bilgi_alani_bolum_3 { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-bottom: 1px dashed #aaaaaa; }

/* CLIENT-SPECIFIC STYLES */
body, table, td, a{-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%;} /* Prevent WebKit and Windows mobile changing default text sizes */
table, td{mso-table-lspace: 0pt; mso-table-rspace: 0pt;} /* Remove spacing between tables in Outlook 2007 and up */
img{-ms-interpolation-mode: bicubic;} /* Allow smoother rendering of resized image in Internet Explorer */

/* RESET STYLES */
img{border: 0; height: auto; line-height: 100%; outline: none; text-decoration: none;}
table{border-collapse: collapse !important;}
body{height: 100% !important; margin: 0 !important; padding: 0 !important; width: 100% !important;}

/* iOS BLUE LINKS */
a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
}

/* MOBILE STYLES */
@media screen and (max-width: 525px) {

    /* ALLOWS FOR FLUID TABLES */
    .wrapper {
      width: 100% !important;
        max-width: 100% !important;
    }

    /* ADJUSTS LAYOUT OF LOGO IMAGE */
    .logo img {
      margin: 0 auto !important;
    }

    /* USE THESE CLASSES TO HIDE CONTENT ON MOBILE */
    .mobile-hide {
      display: none !important;
    }

    .img-max {
      max-width: 100% !important;
      width: 100% !important;
      height: auto !important;
    }

    /* FULL-WIDTH TABLES */
    .responsive-table {
      width: 100% !important;
    }

    /* UTILITY CLASSES FOR ADJUSTING PADDING ON MOBILE */
    .padding {
      padding: 10px 5% 15px 5% !important;
    }

    .padding-meta {
      padding: 30px 5% 0px 5% !important;
      text-align: center;
    }

    .padding-copy {
         padding: 10px 5% 10px 5% !important;
      text-align: center;
    }

    .no-padding {
      padding: 0 !important;
    }

    .section-padding {
      padding: 50px 15px 50px 15px !important;
    }

    /* ADJUST BUTTONS ON MOBILE */
    .mobile-button-container {
        margin: 0 auto;
        width: 100% !important;
    }

    .mobile-button {
        padding: 15px 0 !important;
        border: 0 !important;
        font-size: 16px !important;
        display: block !important;
    }

}

/* ANDROID CENTER FIX */
div[style*="margin: 16px 0;"] { margin: 0 !important; }


.yukleniyor { padding: 30px; text-align: center; }
.yukleniyor-ikonu { font-size: 30px;}
.not_alani { display: block; width: 100%; height: 100px; min-height: 100px; margin-top: 20px; border:solid 1px #aaa; outline:none;
  resize: vertical; }
.yazi_alani { display: block; width: 100%; margin-top: 5px; border:solid 1px #aaa; outline:none;
  resize: vertical; }
.gerekli { border:solid 2px #DB4437 !important;  }
.form_baslik { margin-top:15px; }
.dosya_yukleme_hatasi { margin-top:5px; color:#DB4437; }